<template>
  <div class="mobile-container">
    <div class="page-bg img-cover">
      <img src="../../assets/images/login-bg.png" />
    </div>
    <div class="page-content">
      <div class="page-title">
        <div class="title">Hello!</div>
        <div class="sub-title">欢迎来到{{ websiteInfo.SiteName }}</div>
      </div>
      <div class="input-item">
        <div class="title-item">
          <div class="icon img-cover"><img src="../../assets/images/username-icon.png" /></div>
          <div class="name">用户名/手机号</div>
        </div>
        <div class="input">
          <input v-model="from.UserName" type="text" placeholder="请输入您的手机号/用户名" />
        </div>
      </div>
      <div class="input-item">
        <div class="title-item">
          <div class="icon img-cover"><img src="../../assets/images/pwd-icon.png" /></div>
          <div class="name">密码</div>
        </div>
        <div class="input">
          <input v-model="from.Pwd" type="password" placeholder="请输入您的密码" />
          <router-link :to="{path: '/forget'}">忘记密码</router-link>
        </div>
      </div>
      <div class="input-item">
        <div class="title-item">
          <div class="icon img-cover"><img src="../../assets/images/code-icon.png" /></div>
          <div class="name">验证码</div>
        </div>
        <div class="input">
          <input v-model="from.CaptchaValue" type="text" placeholder="请输入验证码" />
          <img v-if="captchaPath" :src="captchaPath" @click="handleGetCaptcha" />
        </div>
      </div>
      <div class="btn-item">
        <div class="btn login public-btn" @click="submit">
          <van-loading v-if="submiting" color="#ffffff" />
          <span v-else>登录</span>
        </div>
        <router-link v-if="showRegister" class="btn" :to="{path: '/register'}">注册</router-link>
      </div>
    </div>
  </div>
</template>

<script>

import { mapMutations, mapActions, mapState } from 'vuex'
 
export default {
  name: "Login",
  data() {
    return {
      submiting: false,
      showRegister: false,
      captchaPath: '',
      from: {
        UserName: '',
        Pwd: '',
        CaptchaValue: '',
      }
    }
  },
  computed: {
    ...mapState(['websiteInfo'])
  },
  mounted () {
    this.handleGetSetting()
    this.handleGetCaptcha()
  },
  methods: {
    ...mapMutations(['SET_TOKEN']),
    ...mapActions(['GetUserInfo']),
    async handleGetSetting() {
      var res = await this.$API.getSet.post({
        Key: 'is_register'
      })
      this.showRegister = res.info == 1
    },
    handleGetCaptcha() {
      this.captchaPath = this.$HTTP.baseURL + '/common/captcha?v=' + (new Date().getTime())
    },
    async submit() {
      const { from } = this
      if (!from.UserName) {
        this.$toast('请输入您的手机号/用户名')
        return
      }
      if (!from.Pwd) {
        this.$toast('请输入您的密码')
        return
      }
      if (!from.CaptchaValue) {
        this.$toast('请输入验证码')
        return
      }
      this.submiting = true
      var res = await this.$API.login.post(from)
      this.submiting = false
      if (res.error == 0) {
        this.SET_TOKEN(res.info)
        var user = await this.GetUserInfo()
        if (user.error == 0) {
          this.$router.push({path: '/'})
        } else {
          this.$dialog.alert({
            type: 'danger',
            title: '提示',
            message: user.info,
            confirmButtonText: '确定'
          })
          this.handleGetCaptcha()
        }
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定'
        })
        this.handleGetCaptcha()
      }
    }
  },
};
</script>

<style lang="less" scoped>
.page-bg {
  width: var(--max-width);
  height: 12rem;
  position: fixed;
  top: 0;
}
.page-content {
  position: relative;
  padding: .98rem .72rem 1.3rem;
  z-index: 1;
  .page-title {
    color: #151d2b;
    font-weight: bold;
    margin-bottom: 1rem;
    .title {
      line-height: .74rem;
      font-size: .52rem;
    }
    .sub-title {
      line-height: .48rem;
      font-size: .32rem;
    }
  }
  .input-item {
    margin-bottom: .24rem;
    border-bottom: .02rem solid #f7f7f7;
    .title-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .icon {
        width: .32rem;
        height: .32rem;
        margin-right: .12rem;
      }
      .name {
        line-height: .42rem;
        font-size: .3rem;
        color: #151d2b;
        font-weight: 500;
      }
    }
    .input {
      height: .86rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        flex: 1;
        height: .4rem;
      }
      img {
        cursor: pointer;
        width: 2rem;
        height: .6rem;
      }
      a {
        cursor: pointer;
      }
    }
  }
  .btn-item {
    padding-top: .68rem;
    .btn {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border: .02rem solid #1399ff;
      border-radius: .12rem;
      margin-bottom: .32rem;
      height: .88rem;
      font-size: .28rem;
      font-weight: bold;
      &.login {
        color: #fff;
      }
    }
  }
}
</style>
